<template>
<!-- 一级路由 -->
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>
